<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" class="pb-0">
        <h1 class="headline">
          {{ $route.path.split("/")[1].toUpperCase() }} Customers
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" class="pb-0">
        <v-text-field
          v-model="emailOrName"
          label="Search by email or name"
          hide-details
          class="mb-5"
        >
          <template slot="append">
            <v-btn color="primary" style="margin-bottom: 6px" @click="getCustomers">
              <v-icon left>mdi-magnify</v-icon>
               Search
            </v-btn>
          </template>
        </v-text-field>

        <v-card elevation="2" color="secondary">
          <v-data-table
            :headers="headers"
            :items="customers"
            :search="search"
            :items-per-page="10"
            :loading="loading"
            :footer-props="{
              itemsPerPageOptions: [10, 50, 100, -1],
            }"
          >
            <template v-slot:top>
              <v-toolbar flat color="white" class="pt-1">
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Filter"
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item.ShopifyCustomerId="{ item }">
                <a :href="`https://${storeUrl}/admin/customers/${item.ShopifyCustomerId}`" target="_blank">
                  {{item.ShopifyCustomerId}}
                </a>
            </template>
            <template v-slot:item.options="{ item }">
              <v-btn
                outlined
                text
                small
                class="ml-2"
                :to="`${$route.path}/${item.CustomerId}`"
                >Details</v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import fb from "@/main";
export default {
  name: "Customers",
  data() {
    return {
      emailOrName: "",
      customers: [],
      password: "",
      loading: false,
      search: "",
      snack: false,
      snackColor: "",
      snackText: "",
      headers: [
        {
          text: "ID",
          value: "CustomerId",
          align: "left",
        },
        {
          text: "Name",
          value: "CustomerName",
          align: "left",
        },
        {
          text: "Email",
          value: "CustomerEmail",
          align: "left",
        },
        { text: "Shopify Customer ID",
          value: "ShopifyCustomerId",
          align: "left",
        },
        {
          text: "Deleted",
          value: "Deleted",
          align: "left",
        },
        {
          text: "",
          value: "options",
          align: "right",
        },
      ],
    };
  },
  watch: {
    $route(to, from) {
      console.log(to, from);
      if (to.fullPath !== from.fullPath) {
        this.store = to.fullPath.split("/")[1];
        this.getCustomers();
      }
    },
  },
  computed: {
    storeUrl() {
      if (this.store === "soundpaint") {
        return "soundpaint.com";
      } else {
        return "8dio-com.myshopify.com";
      }
    },
  },
  async created() {
    try {
      this.store = this.$route.fullPath.split("/")[1];
    } catch (error) {
      this.loading = false;
    }
  },
  methods: {
    async getCustomers() {
      if (this.emailOrName && !this.emailOrName.includes('@') && this.emailOrName.length <= 4) {
        alert("Please enter more than 4 characters to search by name.");
        return;
      }
      if (this.emailOrName) {
        console.log("--------", "getting customer instruments for", this.store);
        this.loading = true;
        const customersCall = fb.functions.httpsCallable("getCustomers");
        const { data: recordset } = await customersCall({
          store: this.store,
          email: this.emailOrName.includes('@') ? this.emailOrName : '',
          name: !this.emailOrName.includes('@') ? this.emailOrName : '',
        });
        if (recordset.length) {
          this.customers = recordset;
        } else {
          this.customers = [];
        }
        this.loading = false;
      }
    },
  },
};
</script>
