<template>
  <v-container fluid>
    <v-row v-if="loading">
      <v-col cols="12" sm="12" md="8">
        <v-skeleton-loader type="card, card, card, card"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-if="!loading">
      <v-col cols="12" sm="12" class="pb-0">
        <h1 class="headline">
          <v-btn
            x-small
            text
            style="margin-top: -3px"
            class="mr-2"
            :to="`/${$route.fullPath.split('/')[1]}/instruments`"
            ><v-icon>chevron_left</v-icon></v-btn
          >
          {{ instrument.Name }}
        </h1>
      </v-col>
    </v-row>
    <v-row v-if="!loading">
      <v-col cols="12" sm="12" md="8">
        <v-card elevation="2" class="mb-4">
          <v-card-title>NAME</v-card-title>
          <v-card-text>
            <v-text-field
              v-model="instrument.Name"
              filled
              label="Name"
              rows="4"
            ></v-text-field>
            <v-btn
              color="primary"
              small
              @click="saveName"
              :loading="savingName"
              :disabled="savingName"
              >Save</v-btn
            >
          </v-card-text>
        </v-card>
        <v-card elevation="2" class="mb-4">
          <v-card-title>SHOPIFY</v-card-title>
          <v-card-text>
            <v-text-field
              v-model="instrument.ShopifyVariantId"
              filled
              label="Shopify Variant ID"
              type="number"
            ></v-text-field>
            <v-text-field
              v-model="instrument.ShopifyProductId"
              filled
              label="Shopify Product ID"
              type="number"
            ></v-text-field>
            <v-btn
              color="primary"
              small
              @click="saveShopifyIds"
              :loading="savingShopifyIds"
              :disabled="savingShopifyIds"
              >Save</v-btn
            >
          </v-card-text>
        </v-card>
        <v-card elevation="2" class="mb-4" v-if="this.store == 'soundpaint'">
          <v-card-title>METADATA</v-card-title>
          <v-card-text>
            <v-textarea
              v-model="instrument.Metadata"
              auto-grow
              filled
              label="Metadata"
              rows="4"
            ></v-textarea>
            <v-btn
              color="primary"
              small
              @click="saveMetadata"
              :loading="savingMetadata"
              :disabled="savingMetadata"
              >Save</v-btn
            >
          </v-card-text>
        </v-card>
        <v-card elevation="2" class="mb-4" v-if="this.store == '8dio'">
          <v-card-title>FILE SIZE</v-card-title>
          <v-card-text>
            <p>To be displayed in the downloader.</p>
            <v-text-field
              v-model="instrument.FileSize"
              filled
              label="File Size"
              rows="4"
              maxlength="10"
            ></v-text-field>
            <v-btn
              color="primary"
              small
              @click="saveFileSize"
              :loading="savingFileSize"
              :disabled="savingFileSize"
              >Save</v-btn
            >
          </v-card-text>
        </v-card>
        <v-card elevation="2" class="mb-4" v-if="this.store == '8dio'">
          <v-card-title>SINGLE FILE ZIP</v-card-title>
          <v-card-text>
            <v-autocomplete
              filled
              label="Select File"
              :items="files[this.store]"
              item-text="Key"
              v-model="instrument.Zip"
              autocomplete
            ></v-autocomplete>
            <v-btn
              color="primary"
              small
              @click="saveZip"
              :loading="savingZip"
              :disabled="savingZip"
              >Save</v-btn
            >
          </v-card-text>
        </v-card>
        <div v-if="!instrument.IsBundle">
          <ObjectList :files="files" :instrument="instrument"></ObjectList>
        </div>
        <Bundle :instrument="instrument"></Bundle>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import fb from "@/main";
import Bundle from "@/components/Bundle.vue";
import ObjectList from "@/components/ObjectList.vue";

export default {
  components: {
    Bundle,
    ObjectList,
  },
  name: "Home",
  data() {
    return {
      currentItem: null,
      instrument: null,
      search: "",
      loading: true,
      saving: false,
      snack: false,
      snackColor: "",
      snackText: "",
      store: null,
      savingFileSize: false,
      savingZip: false,
      savingMetadata: false,
      savingName: false,
      savingShopifyIds: false,
    };
  },
  watch: {
    $route(to, from) {
      console.log(to, from);
      if (to.fullPath !== from.fullPath) {
        this.store = to.fullPath.split("/")[1];
        this.getInstrument();
      }
    },
  },
  async created() {
    this.getFiles();
  },
  computed: {
    ...mapGetters({ user: "user", files: "instrumentFiles" }),
    storeUrl() {
      if (this.store === "soundpaint") {
        return "soundpaint.com";
      } else {
        return "8dio-com.myshopify.com";
      }
    },
  },
  methods: {
    async getFiles() {
      try {
        this.store = this.$route.fullPath.split("/")[1];
        const instrument = await this.getInstrument();
        console.log(
          "!this.files[this.store].length",
          !this.files[this.store].length
        );
        if (!this.files[this.store].length) {
          await this.$store.dispatch("getInstrumentFiles", this.store);
        }
        const zips = this.files[this.store]
          .filter((s) => {
            return s.Key.includes(".zip");
          })
          .map((s) => {
            return s.Key;
          })
          .join(",");
        console.log(zips, "files");
        const instrumentFiles = instrument.ObjectList.split("~");
        instrument.files = this.files[this.store].filter((f) =>
          instrumentFiles.includes(f.Key)
        );
        console.log("instrument", instrument);
        this.instrument = instrument;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Error getting instrument.";
      }
    },
    async getInstrument() {
      console.log("--------", "getting instrument for", this.store);
      const instrumentCall = fb.functions.httpsCallable("getInstrument");
      const { data: recordset } = await instrumentCall({
        store: this.store,
        id: this.$route.params.id,
      });
      return recordset[0];
    },
    async saveZip() {
      try {
        this.savingZip = true;
        const saveInstrumentZip =
          fb.functions.httpsCallable("saveInstrumentZip");
        await saveInstrumentZip({
          store: this.store,
          id: this.instrument.Id,
          zip: this.instrument.Zip,
        });
        this.savingZip = false;
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Successfully saved zip file.";
      } catch (error) {
        this.savingZip = false;
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Error saving zip file.";
      }
    },
    async saveMetadata() {
      try {
        this.savingMetadata = true;
        const saveInstrumentMetadata = fb.functions.httpsCallable(
          "saveInstrumentMetadata"
        );
        await saveInstrumentMetadata({
          store: this.store,
          id: this.instrument.Id,
          metadata: this.instrument.Metadata,
        });
        this.savingMetadata = false;
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Successfully saved metadata.";
      } catch (error) {
        this.savingMetadata = false;
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Error saving metadata.";
      }
    },
    async saveFileSize() {
      try {
        const fileSize = this.instrument.FileSize.trim();
        if (!fileSize.includes(" MB") && !fileSize.includes(" GB")) {
          alert("Please ensure there is a space between the number and the size designation (GB or MB). ##.# GB or ### MB")
          return;
        }
        this.savingFileSize = true;

        const saveInstrumentFileSize =
          fb.functions.httpsCallable("saveInstrumentFileSize");
        await saveInstrumentFileSize({
          store: this.store,
          id: this.instrument.Id,
          fileSize: fileSize,
        });
        this.savingFileSize = false;
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Successfully saved file size.";
      } catch (error) {
        this.savingFileSize = false;
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Error saving file size.";
      }
    },
    async saveName() {
      try {
        this.savingName = true;
        const saveInstrumentName =
          fb.functions.httpsCallable("saveInstrumentName");
        await saveInstrumentName({
          store: this.store,
          id: this.instrument.Id,
          name: this.instrument.Name,
        });
        this.savingName = false;
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Successfully saved name.";
      } catch (error) {
        this.savingName = false;
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Error saving name.";
      }
    },
    async saveShopifyIds() {
      try {
        this.savingShopifyIds = true;
        const saveShopifyIds = fb.functions.httpsCallable("saveShopifyIds");
        await saveShopifyIds({
          store: this.store,
          id: this.instrument.Id,
          variantId: this.instrument.ShopifyVariantId || null,
          productId: this.instrument.ShopifyProductId || null,
        });
        this.savingShopifyIds = false;
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Successfully saved Shopify IDs.";
      } catch (error) {
        this.savingShopifyIds = false;
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Error saving Shopify IDs.";
      }
    },
  },
};
</script>
