<template>
  <v-container fluid>
    <v-dialog
      v-model="dialog.show"
      persistent
      :overlay="false"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="title secondary mb-4"
          >Create Instrument</v-card-title
        >
        <v-card-text>
          <p>Please enter a name for the new instrument.</p>
          <v-text-field
            v-model="dialog.instrument.name"
            filled
            label="Name"
            type="text"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog.show = false"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            @click="createInstrument()"
            :loading="dialog.saving"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="viewFilesDialog"
      persistent
      :overlay="false"
      max-width="600px"
    >
      <v-card v-if="currentItem">
        <v-card-title class="title secondary mb-4">{{
          currentItem.Name
        }}</v-card-title>
        <v-card-text>
          Files:
          <div style="max-height: 200px; overflow: scroll">
            <strong style="white-space: pre">{{
              currentItem.ObjectList.split("~").join("\n")
            }}</strong>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="
              viewFilesDialog = false;
              currentItem = null;
            "
            >close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" sm="12" class="pb-0">
        <h1 class="headline">
          {{ $route.path.split("/")[1].toUpperCase() }} Instruments
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" class="pb-0">
        <p class="pa-2 mt-4">
          Need to create a new instrument?
          <v-btn
            rounded
            outlined
            text
            x-small
            class="ml-2"
            @click="dialog.show = true"
            >Click here</v-btn
          >
        </p>
        <v-card elevation="2" color="secondary">
          <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
          <v-data-table
            v-else
            :headers="headers[this.store]"
            :items="instrumentsList"
            :search="search"
            :items-per-page="10"
            :footer-props="{
              itemsPerPageOptions: [10, 50, 100, -1],
            }"
          >
            <template v-slot:top>
              <v-toolbar flat color="white" class="pt-1">
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Filter"
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item.Zip="{ item }">
              <v-progress-circular
                indeterminate
                :size="20"
                v-if="!zips.length"
                color="grey lighten--2"
              ></v-progress-circular>
              <v-autocomplete
                v-else
                :items="zips"
                v-model="item.Zip"
                label="Zip File"
                @change="updateZip(item)"
                hide-details
                class="mb-2"
                autocomplete
              ></v-autocomplete>
            </template>
            <template v-slot:item.ShopifyProductId="{ item }">
              <a
                :href="`https://${storeUrl}/admin/products/${item.ShopifyProductId}`"
                target="_blank"
              >
                {{ item.ShopifyProductId }}
              </a>
            </template>
            <template v-slot:item.options="{ item }">
              <v-btn
                outlined
                text
                small
                class="ml-2"
                :to="`${$route.path}/${item.Id}`"
                >edit</v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import fb from "@/main";
import { sortBy } from "lodash";
export default {
  name: "Instruments",
  data() {
    return {
      instrumentsList: [],
      currentItem: null,
      viewFilesDialog: false,
      search: "",
      loading: false,
      snack: false,
      snackColor: "",
      snackText: "",
      zips: [],
      dialog: {
        show: false,
        saving: false,
        instrument: {
          name: "",
        },
      },
      products: [],
      store: null,
      headers: {
        "8dio": [
          {
            text: "Id",
            value: "Id",
            width: 100,
            align: "left",
          },
          {
            text: "Name",
            value: "Name",
            width: 320,
          },
          {
            text: "Shopify Product ID",
            value: "ShopifyProductId",
          },
          {
            text: "Bundle",
            value: "IsBundle",
            align: "left",
          },
          {
            text: "Zip",
            value: "Zip",
            align: "left",
          },
          {
            text: "",
            value: "options",
            align: "right",
          },
        ],
        soundpaint: [
          {
            text: "Id",
            value: "Id",
            width: 100,
            align: "left",
          },
          {
            text: "Name",
            value: "Name",
            width: 320,
          },
          {
            text: "Shopify Product ID",
            value: "ShopifyProductId",
          },
          {
            text: "Bundle",
            value: "IsBundle",
            align: "left",
          },
          {
            text: "Files",
            value: "fileCount",
            align: "left",
            width: 120,
          },
          {
            text: "Checksum",
            value: "checksum",
            align: "left",
            width: 120,
          },
          {
            text: "",
            value: "options",
            align: "right",
          },
        ],
      },
    };
  },
  watch: {
    $route(to, from) {
      console.log(to, from);
      if (to.fullPath !== from.fullPath) {
        this.store = to.fullPath.split("/")[1];
        this.getProducts();
      }
    },
  },
  async created() {
    try {
      this.store = this.$route.fullPath.split("/")[1];
      await this.getProducts();
      if (!this.files.length) {
        await this.$store.dispatch("getInstrumentFiles", this.store);
        this.zips = this.files[this.store]
          .filter((s) => {
            return s.Key.includes(".zip");
          })
          .map((s) => {
            return s.Key;
          });
      }
    } catch (error) {
      this.loading = false;
      console.log(error);
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Error getting products or files.";
    }
  },
  computed: {
    ...mapGetters({ user: "user", files: "instrumentFiles" }),
    storeUrl() {
      if (this.store === "soundpaint") {
        return "soundpaint.com";
      } else {
        return "8dio-com.myshopify.com";
      }
    },
  },
  methods: {
    async updateZip(item) {
      try {
        this.savingZip = true;
        const saveInstrumentZip =
          fb.functions.httpsCallable("saveInstrumentZip");
        await saveInstrumentZip({
          store: this.store,
          id: item.Id,
          zip: item.Zip,
        });
        this.savingZip = false;
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Successfully saved zip file.";
      } catch (error) {
        this.savingZip = false;
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Error saving zip file.";
      }
    },
    async getProducts() {
      console.log("--------", "getting products for", this.store);
      this.loading = true;
      const instrumentsCall = fb.functions.httpsCallable("getInstruments");
      const { data: recordset } = await instrumentsCall({
        store: this.store,
      });
      console.log(this.store, "instruments", recordset.length);
      let instruments = recordset;
      instruments = instruments.map((i) => {
        return {
          fileList: i.ObjectList.split("~"),
          fileCount: !i.ObjectList ? 0 : i.ObjectList.split("~").length,
          checksum: (i.Metadata || "").split("Checksum").length - 1,
          ...i,
        };
      });
      instruments = sortBy(instruments, "Id").reverse();
      this.instrumentsList = instruments;
      this.loading = false;
    },
    async createInstrument() {
      const instrument = this.dialog.instrument;

      if (!instrument.name) {
        alert("Name is a required field.");
        return;
      }

      try {
        this.dialog.saving = true;
        const createInstrument = fb.functions.httpsCallable("createInstrument");
        const { data: instrumentId } = await createInstrument({
          store: this.store,
          instrument: instrument,
        });
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Successfully created instrument.";
        setTimeout(() => {
          this.$router.push(`${this.$route.path}/${instrumentId}`);
        }, "1500");
      } catch (e) {
        this.dialog.saving = false;
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Error creating instrument.";
      }
    },
    async edit(item, type) {
      this.currentItem = null;
      this.currentItem = JSON.parse(JSON.stringify(item));
      if (type == "viewFiles") {
        this.viewFilesDialog = true;
      }
    },
  },
};
</script>
