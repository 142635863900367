var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12"}},[_c('h1',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$route.path.split("/")[1].toUpperCase())+" Customers ")])])],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{staticClass:"mb-5",attrs:{"label":"Search by email or name","hide-details":""},model:{value:(_vm.emailOrName),callback:function ($$v) {_vm.emailOrName=$$v},expression:"emailOrName"}},[_c('template',{slot:"append"},[_c('v-btn',{staticStyle:{"margin-bottom":"6px"},attrs:{"color":"primary"},on:{"click":_vm.getCustomers}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-magnify")]),_vm._v(" Search ")],1)],1)],2),_c('v-card',{attrs:{"elevation":"2","color":"secondary"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.customers,"search":_vm.search,"items-per-page":10,"loading":_vm.loading,"footer-props":{
            itemsPerPageOptions: [10, 50, 100, -1],
          }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"pt-1",attrs:{"flat":"","color":"white"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":"Filter","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.ShopifyCustomerId",fn:function(ref){
          var item = ref.item;
return [_c('a',{attrs:{"href":("https://" + _vm.storeUrl + "/admin/customers/" + (item.ShopifyCustomerId)),"target":"_blank"}},[_vm._v(" "+_vm._s(item.ShopifyCustomerId)+" ")])]}},{key:"item.options",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","text":"","small":"","to":((_vm.$route.path) + "/" + (item.CustomerId))}},[_vm._v("Details")])]}}])})],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
          var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snack = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }