<template>
  <v-container fluid>
    <v-dialog v-model="dialog.show" persistent :overlay="false" max-width="600px">
      <v-card>
        <v-card-title class="title secondary mb-4">Add Instrument</v-card-title>
        <v-card-text>
          <p>
            Please select an instrument to add it to {{customerName}}'s account.
          </p>
          <v-autocomplete
            v-model="dialog.instrument"
            :items="instrumentsList"
            dense
            filled
            label="Instruments"
            item-text="Name"
            item-value="Id"
            return-object
            @change="dialog.createShopifyOrder = false;"
          ></v-autocomplete>

          <p v-if="dialog.instrument">
            Instrument ID: {{dialog.instrument.Id}}
            <br/>
            Bundle: {{dialog.instrument.IsBundle ? 'Yes' : 'No'}}
            <br/>
            Shopify Product: {{dialog.instrument.ShopifyProductId ? 'Yes' : 'No'}}
            <span v-if="dialog.instrument.ShopifyProductId">
              <br/>
              Shopify Product ID:
              <a :href="`https://${storeUrl}/admin/products/${dialog.instrument.ShopifyProductId}`" target="_blank">
                {{dialog.instrument.ShopifyProductId}}
              </a>
              <br/>
              Shopify Variant ID: {{dialog.instrument.ShopifyVariantId || 'N/A'}}
            </span>
          </p>
          <p v-if="dialog.instrument && dialog.instrument.IsBundle">
            Adding this bundle to {{customerName}}'s account will auto-add all child instruments too.
          </p>
          <v-checkbox
            v-show="dialog.instrument && dialog.instrument.ShopifyVariantId && shopifyCustomerId"
            v-model="dialog.createShopifyOrder"
            label="Create Shopify Order"
            hide-details
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="
              dialog.show = false;
            "
            >Cancel</v-btn
          >
          <v-btn color="primary" @click="addCustomerInstrument()" :loading="saving">
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row v-if="email">
      <v-col cols="12" sm="12" class="pb-0">
        <h1 class="headline">
          <v-btn
            x-small
            text
            style="margin-top: -3px"
            class="mr-2"
            :to="`/${$route.fullPath.split('/')[1]}/customers`"
            ><v-icon>chevron_left</v-icon></v-btn
          >
          {{customerName}} ({{email}})
          <v-switch
            v-if="store === 'soundpaint'"
            class="ml-2"
            v-model="designer"
            label="Designer"
            color="success"
            :disabled="saving"
            :loading="saving"
            @change="toggleCustomerDesigner"
            style="display: inline-block;"
          ></v-switch>
          <v-switch
            v-if="store === 'soundpaint'"
            class="ml-2"
            v-model="disabled"
            label="Disabled"
            color="error"
            :disabled="saving"
            :loading="saving"
            @change="toggleCustomerDisabled"
            style="display: inline-block;"
          ></v-switch>
          <v-switch
            v-if="store === 'soundpaint'"
            class="ml-2"
            v-model="deleted"
            label="Deleted"
            color="error"
            :disabled="saving"
            :loading="saving"
            @change="toggleCustomerDeleted"
            style="display: inline-block;"
          ></v-switch>
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" class="pb-0">
        <p class="pl-1 mt-0" v-if="shopifyCustomerId"><v-icon class="mr-2">shopify</v-icon>Shopify Customer ID: 
          <a :href="`https://${storeUrl}/admin/customers/${shopifyCustomerId}`" target="_blank">{{shopifyCustomerId}}</a>
        </p>
        <p class="pl-1 mt-0" v-if="accessKey"><v-icon class="mr-2">key</v-icon>Access Key: {{accessKey}}</p>
        <p class="pl-1" v-if="email">
          <v-icon class="mr-2">library_music</v-icon>Need to add an instrument to {{customerName}}'s account?
          <v-btn
            rounded
            outlined
            text
            x-small
            class="ml-2"
            @click="showDialog"
            >Click here</v-btn
          >
        </p>
        <p><v-chip x-small class="mr-2" style="background: rgba(0, 0, 0, 0.54);color: white;">Max Downloads</v-chip>Sets download limit to 999999999 when enabled.</p>

        <v-card elevation="2" class="mb-4">
          <v-card-title>
            Instruments
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="customerInstrumentsList"
            :search="search"
            :items-per-page="10"
            :loading="loading"
            :footer-props="{
              itemsPerPageOptions: [10, 50, 100, -1],
            }"
          >
            <template v-slot:top>
              <v-toolbar flat color="white" class="pt-1">
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Filter"
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item.InstrumentId="{ item }">
                <a :href="`/${store}/instruments/${item.InstrumentId}`" target="_blank">
                  {{item.InstrumentId}}
                </a>
            </template>
            <template v-slot:item.ShopifyProductId="{ item }">
                <a :href="`https://${storeUrl}/admin/products/${item.ShopifyProductId}`" target="_blank">
                  {{item.ShopifyProductId}}
                </a>
            </template>
            <template v-slot:item.Disabled="{ item }">
              <div>
                {{ item.Disabled ? 'Yes' : 'No' }}
                <v-btn
                  v-if="item.Disabled"
                  outlined
                  text
                  small
                  class="ml-2"
                  @click="removeDownloaderBlock(item)"
                  :disabled="saving"
                  :loading="saving"
                  >Reset</v-btn
                >
              </div>
            </template>
            <template v-slot:item.Deleted="{ item }">
              <v-switch
                class="ml-2"
                v-model="item.Deleted"
                color="error"
                :disabled="saving"
                :loading="saving"
                @change="toggleDownloaderDelete(item.Deleted, item.DownloaderId)"
              ></v-switch>
            </template>
            <template v-slot:item.DownloadsAmount="{ item }">
              <v-switch
                class="ml-2"
                v-model="item.DownloadsAmount"
                color="success"
                :false-value="20"
                :true-value="999999999"
                :disabled="saving"
                :loading="saving"
                @change="toggleDownloadsAmount(item.DownloadsAmount, item.DownloaderId)"
              ></v-switch>
            </template>
          </v-data-table>
        </v-card>
        <div v-if="store === 'soundpaint'">
          <ClientAppSessions></ClientAppSessions>
        </div>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import fb from "@/main";
import ClientAppSessions from "@/components/ClientAppSessions.vue";

export default {
  components: {
    ClientAppSessions
  },
  name: "Customers",
  data() {
    return {
      password: "",
      email: "",
      customerName: "",
      accessKey: "",
      shopifyCustomerId: null,
      designer: false,
      disabled: false,
      loading: false,
      search: "",
      snack: false,
      snackColor: "",
      snackText: "",
      dialog: {
        show: false,
        instrument: null,
        createShopifyOrder: false
      },
      saving: false,
      headers: [
        {
          text: "ID",
          value: "InstrumentId",
          align: "left",
        },
        {
          text: "Instrument",
          value: "InstrumentName",
          align: "left",
        },
        { text: "Shopify Product ID",
          value: "ShopifyProductId",
          align: "left",
        },
        {
          text: "Serial",
          value: "Serial",
          width: 300,
        },
        {
          text: "Download Attempts",
          value: "DownloadAttempts",
          width: 100,
        },
        {
          text: "Blocked",
          value: "Disabled",
          align: "left",
        },
        {
          text: "Deleted",
          value: "Deleted",
          align: "left",
        },
        {
          text: "Max Downloads",
          value: "DownloadsAmount",
          align: "left",
        },
      ],
      customerInstrumentsList: [],
      customerInstrumentsGrouped: {},
      instrumentsList: []
    };
  },
  watch: {
    $route(to, from) {
      console.log(to, from);
      if (to.fullPath !== from.fullPath) {
        this.store = to.fullPath.split("/")[1];
        this.getCustomerInstruments();
        this.getInstruments();
      }
    },
  },
  computed: {
    storeUrl() {
      if (this.store === "soundpaint") {
        return "soundpaint.com";
      } else {
        return "8dio-com.myshopify.com";
      }
    },
  },
  async created() {
    try {
      this.store = this.$route.fullPath.split("/")[1];
      this.loading = true;
      await this.getCustomer();
      await this.getAccessKey();
      await this.getInstruments();
      await this.getCustomerInstruments();
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  },
  methods: {
    groupBy(xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    async getInstruments() {
      console.log("--------", "getting products for", this.store);
      const instrumentsCall = fb.functions.httpsCallable("getInstruments");
      const { data: recordset } = await instrumentsCall({
        store: this.store,
      });
      console.log(this.store, "instruments", recordset.length);
      this.instrumentsList = recordset.filter((r) => {return !this.customerInstrumentsGrouped[r.Name]});
    },
    async getAccessKey() {
      if (this.store === 'soundpaint') {
        const accessKeyCall = fb.functions.httpsCallable("getCustomerAccessKey");
        const { data: recordset } = await accessKeyCall({
          store: this.store,
          id: this.$route.params.id,
        });
        if (recordset.length) {
          this.accessKey = recordset[0].AccessKey;
        }
      }
    },
    async getCustomer() {
      const customersCall = fb.functions.httpsCallable("getCustomers");
      const { data: recordset } = await customersCall({
        store: this.store,
        id: this.$route.params.id,
      });
      if (recordset.length) {
        this.customers = recordset;
        this.customerName = recordset[0].CustomerName;
        this.shopifyCustomerId = recordset[0].ShopifyCustomerId;
        this.email = recordset[0].CustomerEmail;
        this.designer = (recordset[0].Type === 3);
        this.disabled = recordset[0].Disabled;
        this.deleted = recordset[0].Deleted;
      }
    },
    async getCustomerInstruments() {
      console.log("--------", "getting customer instruments for", this.store);
      const customerInstrumentsCall = fb.functions.httpsCallable("getCustomerInstruments");
      const { data: recordset } = await customerInstrumentsCall({
        store: this.store,
        id: this.$route.params.id,
      });
      if (recordset.length) {
        this.customerInstrumentsGrouped = this.groupBy(recordset, 'InstrumentName');
        this.customerInstrumentsList = recordset;
      } else {
        this.customerInstrumentsList = [];
      }
    },
    async removeDownloaderBlock(item) {
      this.saving = true;
      const removeDownloaderBlockCall = fb.functions.httpsCallable("removeDownloaderBlock");
      await removeDownloaderBlockCall({
        store: this.store,
        downloaderId: item.DownloaderId,
      });
      item.Disabled = false;
      this.saving = false;
      this.snack = true;
      this.snackColor = "success";
      this.snackText = `Successfully reset disabled indicator.`;
    },
    async addCustomerInstrument() {
      if (!this.dialog.instrument || !this.dialog.instrument.Id || !this.email) {
        return
      }

      const instrumentOwned = this.customerInstrumentsList.find((inst) => {
        // backend won't add dupes
        // pre-check non-bundles...
        // don't pre-check bundles... handles when a bundle is expanded and missing instruments need to be added to customer
        return inst.InstrumentId == this.dialog.instrument.Id && !this.dialog.instrument.IsBundle
      });
      if (instrumentOwned) {
        alert("Sorry, this customer already owns this instrument. Please try again.")
        return
      }

      this.saving = true;
      const addDownloaderRecordCall = fb.functions.httpsCallable("addDownloaderRecord");
      await addDownloaderRecordCall({
        store: this.store,
        instrument: this.dialog.instrument,
        id: this.$route.params.id,
      });

      if (this.dialog.createShopifyOrder) {
        const createShopifyOrderCall = fb.functions.httpsCallable("createShopifyOrder");
        await createShopifyOrderCall({
          store: this.store,
          variantId: this.dialog.instrument.ShopifyVariantId,
          customerId: this.shopifyCustomerId,
        });
      }

      this.getCustomerInstruments();
      this.dialog.show = false;
      this.saving = false;
      this.snack = true;
      this.snackColor = "success";
      this.snackText = `Successfully added instrument.`;
    },
    async toggleCustomerDesigner() {
      this.saving = true;
      const type = this.designer ? 3 : 0;
      const updateCustomerTypeCall = fb.functions.httpsCallable("updateCustomerType");
      await updateCustomerTypeCall({
        store: this.store,
        type: type,
        id: this.$route.params.id,
      });
      this.designer = (type === 3);
      this.saving = false;
    },
    async toggleCustomerDisabled() {
      this.saving = true;
      const disabled = this.disabled ? 1 : 0;
      const updateCustomerTypeCall = fb.functions.httpsCallable("updateCustomerDisabled");
      await updateCustomerTypeCall({
        store: this.store,
        disabled: disabled,
        id: this.$route.params.id,
      });
      this.disabled = (disabled === 1);
      this.saving = false;
    },
    async toggleCustomerDeleted() {
      this.saving = true;
      const deleted = this.deleted ? 1 : 0;
      const updateCustomerTypeCall = fb.functions.httpsCallable("updateCustomerDeleted");
      await updateCustomerTypeCall({
        store: this.store,
        deleted: deleted,
        id: this.$route.params.id,
      });
      this.deleted = (deleted === 1);
      this.saving = false;
    },
    async toggleDownloaderDelete(deleted, downloaderId) {
      this.saving = true;
      const bool = deleted ? 1 : 0;
      const updateDownloaderDeletedCall = fb.functions.httpsCallable("updateDownloaderDeleted");
      await updateDownloaderDeletedCall({
        store: this.store,
        deleted: bool,
        id: downloaderId,
      });
      deleted = !!bool;
      this.saving = false;
    },
    async toggleDownloadsAmount(downloadsAmount, downloaderId) {
      this.saving = true;
      const updateDownloadsAmount = fb.functions.httpsCallable("updateDownloadsAmount");
      await updateDownloadsAmount({
        store: this.store,
        downloadsAmount: downloadsAmount,
        id: downloaderId,
      });
      this.snack = true;
      this.snackColor = "success";
      this.snackText = `Successfully updated downloads amount.`;
      this.saving = false;
    },
    showDialog() {
      this.dialog = {
        show: true,
        instrument: null,
        createShopifyOrder: false
      }
    }
  },
};
</script>
