<template>
  <v-container fluid>
    <v-dialog v-model="dialog" persistent :overlay="false" max-width="600px">
      <v-card v-if="currentItem">
        <v-card-title class="title secondary mb-4">{{
          currentItem.title
        }}</v-card-title>
        <v-card-text>
          <p>
            Select the correct instrument below. This will add the shopify
            variant ID to the instrument record in the database.
          </p>
          <div v-for="v in currentItem.variants" :key="v.id">
            <v-autocomplete
              placeholder="Instrument"
              :items="instrumentsList"
              return-object
              item-text="Name"
              item-value="Id"
              v-model="v.instrument"
              :hint="v.title === 'Default Title' ? '' : v.title"
              persistent-hint
            >
            </v-autocomplete>
            <div
              v-if="v.instrument && v.instrument.ObjectList.length"
              class="mt-3"
            >
              Files:
              <div style="max-height: 200px; overflow: scroll">
                <strong style="white-space: pre">{{
                  v.instrument.ObjectList.split("~").join("\n")
                }}</strong>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="
              dialog = false;
              currentItem = null;
            "
            >Cancel</v-btn
          >
          <v-btn color="primary" @click="save()" :loading="saving">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" sm="12" class="pb-0">
        <h1 class="headline">
          {{ $route.path.split("/")[1].toUpperCase() }} Products
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" class="pb-0">
        <v-card elevation="2" color="secondary">
          <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
          <v-data-table
            v-else
            :headers="headers"
            :items="products"
            :search="search"
          >
            <template v-slot:top>
              <v-toolbar flat color="white" class="pt-1">
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Filter"
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item.status="{ item }">
              <v-chip small v-if="item.status === 'active'" color="success">{{
                item.status
              }}</v-chip>
              <v-chip small v-else>{{ item.status }}</v-chip>
            </template>
            <template v-slot:item.variants="{ item }">
              <div v-for="v in item.variants" :key="v.id">
                <v-icon small color="success" v-if="v.dbVariantFound"
                  >check_circle</v-icon
                >
                <v-icon small color="error" v-else>error</v-icon>
                {{ v.title === "Default Title" ? "" : v.title }}
              </div>
            </template>
            <template v-slot:item.productMatch="{ item }">
              <div v-for="p in item.dbProduct" :key="p.id">
                {{ p.Name }}
              </div>
            </template>
            <template v-slot:item.view="{ item }">
              <v-btn small outlined text @click="edit(item)">edit</v-btn>
              <v-btn
                outlined
                text
                target="_blank"
                small
                class="ml-2"
                :href="`https://${storeUrl}/products/${item.handle}`"
                >product<v-icon x-small>call_made</v-icon></v-btn
              >
              <v-btn
                outlined
                text
                target="_blank"
                small
                class="ml-2"
                :href="`https://${storeUrl}/admin/products/${item.id}`"
                >shopify<v-icon x-small>call_made</v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import fb from "@/main";
import { sortBy } from "lodash";
export default {
  name: "Home",
  data() {
    return {
      instrumentsList: [],
      currentItem: null,
      dialog: false,
      search: "",
      loading: false,
      saving: false,
      snack: false,
      snackColor: "",
      snackText: "",
      products: [],
      store: null,
      headers: [
        {
          text: "Title",
          value: "title",
          width: 280,
        },
        {
          text: "Status",
          value: "status",
          width: 100,
        },
        {
          text: "Variant Check",
          value: "variants",
          sortable: false,
          width: 220,
        },
        {
          text: "DB Product Name",
          value: "productMatch",
        },
        {
          text: "",
          align: "end",
          value: "view",
        },
      ],
    };
  },
  watch: {
    $route(to, from) {
      console.log(to, from);
      if (to.fullPath !== from.fullPath) {
        this.store = to.fullPath.split("/")[1];
        this.getProducts();
      }
    },
  },
  async created() {
    try {
      this.store = this.$route.fullPath.split("/")[1];
      this.getProducts();
    } catch (error) {
      this.loading = false;
      console.log(error);
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Error getting products.";
    }
  },
  computed: {
    ...mapGetters(["user"]),
    storeUrl() {
      if (this.store === "soundpaint") {
        return "soundpaint.com";
      } else {
        return "8dio-com.myshopify.com";
      }
    },
  },
  methods: {
    async getProducts() {
      console.log("--------", "getting products for", this.store);
      this.loading = true;
      const productsCall = fb.functions.httpsCallable("getProducts");
      const { data: products } = await productsCall({ store: this.store });
      console.log(
        this.store,
        "products",
        products.find((e) => e.title == "Vintage Mello-D")
      );

      const instrumentsCall = fb.functions.httpsCallable("getInstruments");
      const { data: recordset } = await instrumentsCall({
        store: this.store,
      });
      console.log(this.store, "instruments", recordset.length);
      const instruments = recordset;
      products.forEach((p) => {
        p.dbProduct = instruments.filter((i) => {
          if (Number(i.ShopifyProductId) === p.id) {
            return i;
          }
        });
        p.variants.forEach((v) => {
          v.dbVariantFound = instruments.some((i) => {
            return v.id === Number(i.ShopifyVariantId);
          });
          v.instrument = instruments.find((i) => {
            if (v.id === Number(i.ShopifyVariantId)) {
              return i.Id;
            }
          });
        });
      });
      const productsClean = products.filter(
        (p) =>
          ![
            "8Dio_Test_Convert_RAR_To_Zip",
            "Soundpaint Gift Card",
            "test-product",
            "temp-product",
            "8Dio Gift Card",
          ].includes(p.title)
      );
      this.instrumentsList = instruments;
      this.products = sortBy(productsClean, "created_at").reverse();
      this.loading = false;
    },
    async edit(item) {
      this.currentItem = null;
      this.currentItem = JSON.parse(JSON.stringify(item));
      this.dialog = true;
    },
    async save() {
      // productId, variantId, instrumentId
      try {
        this.saving = true;
        const updateShopifyVariant = fb.functions.httpsCallable(
          "updateShopifyVariant"
        );
        console.log("this.currentItem", this.currentItem);

        for (const v of this.currentItem.variants) {
          const productId = v.product_id;
          const variantId = v.id;
          const instrumentId = v.instrument.Id;
          const result = await updateShopifyVariant({
            productId,
            variantId,
            instrumentId,
            store: this.store,
          });
          console.log(result);
        }
        this.saving = false;
        this.dialog = false;
        this.getProducts();
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Successfully updated!";
      } catch (e) {
        console.log(e);
        this.saving = false;
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Error updating instrument.";
      }
    },
  },
};
</script>
